.countdown {
    display: grid;
    margin: 1em auto;
    width: 10em;
    height: 10em;

}
.countdown::after {
    grid-column: 1;
    grid-row: 1;
    place-self: center;
    font: 5em/2 ubuntu mono, consolas, monaco, monospace;
    animation: num 3s steps(1);
    content: counter(s);
}

@keyframes num {
    0% {
        counter-reset: s 3;
    }
    33% {
        counter-reset: s 2;
    }
    66% {
        counter-reset: s 1;
    }
    100% {
        counter-reset: s 0;
    }
}
svg {
    grid-column: 1;
    grid-row: 1;
}

[r] {
    fill: none;
    stroke: silver;
}
[r] + [r] {
    transform: rotate(-90deg);
    stroke-linecap: round;
    stroke: #8A9B0F;
    animation: arc 3s linear;
    animation-name: arc, col;
}

@keyframes arc {
    0% {
        stroke-dashoffset: 0px;
    }
}
@keyframes col {
    0% {
        stroke: #e50d24;
    }
    100% {
        stroke: #801bd2;
    }
}
