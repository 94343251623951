#hubspot-form {
    /* width: 1000px;
    margin: 0 auto; */
    margin-top: 100px;
    margin-left: 10px;
    .hs-form {
      background-color: white;
      /* border: 1px solid #EBECF0; */
      padding: 20px;
      /* border-radius: 79px; */
      width: 500px;

      margin-left: 15px;
      margin-bottom: 20px;
      /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
      /* #hsForm_289518d1-3726-4455-8d4d-64e7addff3e6 {
        width: 1000px;
      } */
      /* #label-lastname-289518d1-3726-4455-8d4d-64e7addff3e6 > span {
        display: none;
      } */
  #lastname-289518d1-3726-4455-8d4d-64e7addff3e6 {
    padding: 10px;
    border: 1px solid #EBECF0;
    border-radius: 79px;
    flex-basis: 100%;
    width: 920px;
    /* max-width: 920px; */
    margin-top: 10px;
    margin-bottom: 9px;
  }
  @media (min-width: 600px) and (max-width: 900px) {
    #lastname-289518d1-3726-4455-8d4d-64e7addff3e6,
    #firstname-289518d1-3726-4455-8d4d-64e7addff3e6,
    #phone-289518d1-3726-4455-8d4d-64e7addff3e6,
    #company-289518d1-3726-4455-8d4d-64e7addff3e6,
    #email-289518d1-3726-4455-8d4d-64e7addff3e6 {
      width: 550px !important;
      max-width: none !important;
    }
  }
  @media (min-width: 901px) and (max-width: 1000px) {
    #lastname-289518d1-3726-4455-8d4d-64e7addff3e6,
    #firstname-289518d1-3726-4455-8d4d-64e7addff3e6,
    #phone-289518d1-3726-4455-8d4d-64e7addff3e6,
    #company-289518d1-3726-4455-8d4d-64e7addff3e6,
    #email-289518d1-3726-4455-8d4d-64e7addff3e6 {
      width: 800px !important;
      max-width: none !important;
    }
  }
  @media (min-width: 400px) {
    #lastname-289518d1-3726-4455-8d4d-64e7addff3e6,
    #firstname-289518d1-3726-4455-8d4d-64e7addff3e6,
    #phone-289518d1-3726-4455-8d4d-64e7addff3e6,
    #company-289518d1-3726-4455-8d4d-64e7addff3e6,
    #email-289518d1-3726-4455-8d4d-64e7addff3e6 {
      width: 590px !important;
      max-width: none !important;
    }
  }
  @media (min-width: 1001px) {
    #lastname-289518d1-3726-4455-8d4d-64e7addff3e6,
    #firstname-289518d1-3726-4455-8d4d-64e7addff3e6,
    #phone-289518d1-3726-4455-8d4d-64e7addff3e6,
    #company-289518d1-3726-4455-8d4d-64e7addff3e6,
    #email-289518d1-3726-4455-8d4d-64e7addff3e6 {
      width: 800px !important;
      max-width: none !important;
    }
  }
  @media (max-width: 766px) {
    #lastname-289518d1-3726-4455-8d4d-64e7addff3e6,
    #firstname-289518d1-3726-4455-8d4d-64e7addff3e6,
    #phone-289518d1-3726-4455-8d4d-64e7addff3e6,
    #company-289518d1-3726-4455-8d4d-64e7addff3e6,
    #email-289518d1-3726-4455-8d4d-64e7addff3e6 {
      width: 200px !important;
      max-width: none !important;
    }
  }
  @media (min-width: 767px) and (max-width: 1000px) {
    .actions > input {
      margin-left: 360px !important;
      
    }
  }
  @media (min-width: 1001px) {
    .actions > input {
      margin-left: 800px !important;
      
    }
  }
  @media (min-width: 1001px) {
    .actions > input{
      margin-left: 570px !important;
    }
  }
  
  @media (max-width: 766px) {
    .actions > input {
      margin-left: -5px !important;
      width: 200px !important;
    }
  }
  #firstname-289518d1-3726-4455-8d4d-64e7addff3e6 {
    padding: 10px;
    border: 1px solid #EBECF0;
    border-radius: 79px;
    flex-basis: 100%;
    width: 920px;
    margin-top: 10px;
    margin-bottom: 9px;
  }
  #phone-289518d1-3726-4455-8d4d-64e7addff3e6 {
    padding: 10px;
    border: 1px solid #EBECF0;
    border-radius: 79px;
    flex-basis: 100%;
    width: 920px;
    margin-top: 10px;
    margin-bottom: 9px;
  }
  #company-289518d1-3726-4455-8d4d-64e7addff3e6 {
    padding: 10px;
    border: 1px solid #EBECF0;
    border-radius: 79px;
    flex-basis: 100%;
    width: 920px;
    margin-top: 10px;
    margin-bottom: 9px;
  }
  #email-289518d1-3726-4455-8d4d-64e7addff3e6 {
    padding: 10px;
    border: 1px solid #EBECF0;
    border-radius: 79px;
    flex-basis: 100%;
    width: 920px;
    margin-top: 10px;
    margin-bottom: 9px;
  }
  #label-lastname-289518d1-3726-4455-8d4d-64e7addff3e6 {
    color: #828A92;
    font-size: 14px;
    margin-left: 10px;
    margin-top: 20px;

  }
  #label-firstname-289518d1-3726-4455-8d4d-64e7addff3e6 {
    color: #828A92;
    font-size: 14px;
    margin-left: 10px;
    margin-top: 20px;
  }
  #label-phone-289518d1-3726-4455-8d4d-64e7addff3e6 {
    color: #828A92;
    font-size: 14px;
    margin-left: 10px;

  }
  #label-company-289518d1-3726-4455-8d4d-64e7addff3e6 {
    color: #828A92;
    font-size: 14px;
    margin-left: 10px;

  }
  #label-email-289518d1-3726-4455-8d4d-64e7addff3e6 {
    color: #828A92;
    font-size: 14px;
    margin-left: 10px;


  }
  .actions > input{
    border-radius: 54px;
    background-color: #176FFF;
    color: white;
    width: 50%;
    height: 50px;
    margin-top: 10px;
    margin-left: 690px;

  }
  .ul {
    list-style: none !important;
  }
  .hs-error-msg, .hs-main-font-element {
    color: red;
    font-size: 12px;
  }
  #submitted-message, .hs-main-font-element, .hs-form-289518d1-3726-4455-8d4d-64e7addff3e6, .hs-form-289518d1-3726-4455-8d4d-64e7addff3e6_79124e92-e52c-4768-b3b8-8d714f7e8906 > p {
    color: red;
  }
      /* &__actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-top: 20px;
  
        &__submit {
          background-color: #3182ce;
          color: white;
          border: none;
          padding: 10px 20px;
          border-radius: 79px;
          cursor: pointer;
          transition: background-color 0.2s ease-in-out;
  
          &:hover {
            background-color: #2c5282;
          }
        }
      }
   */
      /* &__field {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
  
        &__label {
          font-weight: bold;
          margin-right: 10px;
          flex-basis: 100%;
        }
  
        &__input {
          padding: 10px;
          border: 1px solid #EBECF0;
          border-radius: 79px;
          flex-basis: 100%;
          width: 100%;
  
          &:focus {
            outline: none;
            box-shadow: 0 0 0 2px #63b3ed;
          }
        }
      } */
    }
  }
  